import React, { useEffect, useState } from 'react';
import css from './topbar.module.css';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCartListingsById, recalculateCart } from '../../CartPage/CartPage.duck';

// icons
import { ShoppingCartIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import DropDownMenu from './DropDownMenu';


const category_ids = {
  WOMEN_APPAREL: "womens_apparel",
  WOMEN_ACCESSORIES: "womens_accessories",
  MENS_APPAREL: "mens_apparel",
  MENS_ACCESSORIES: "mens_accessories",
  HOME: "home"
}

export const calculateCartCount = (cart) => {
  let totalCount = 0;
  Object.values(cart || {})?.forEach(item => {
    Object.values(item || {})?.forEach(subItem => {
      totalCount += (subItem?.count || 0);
      if (subItem?.variants && subItem?.variants?.length > 0) {
        const variants = subItem?.variants;
        const variantsTotal = variants?.reduce((total, item) => total + (item?.count || 0), 0);
        totalCount += variantsTotal
      }
    });
  });
  return totalCount;
}

const TopbarSectionComponent = (props) => {
  const { isAuthenticated, currentUser, recalculateCart, config = {} } = props;
  const [keywords, setKeywords] = useState('');
  const history = useHistory();
  const [mounted, setMounted] = useState(false);
  const authenticatedOnClientSide = mounted && isAuthenticated;


  const [hoveringOver, setHoveringOver] = useState('');

  const { cart } = currentUser?.attributes?.profile?.privateData || {};
  const cartCount = calculateCartCount(cart);

  const userType = currentUser?.attributes?.profile?.publicData?.userType || {};
  const isUserCustomer = userType === 'customer';

  const categoryConfiguration = config.categoryConfiguration || {};
  const listingCategories = categoryConfiguration.categories;

  const notificationDot =
    cartCount > 0 ? <div className={css.notificationDot} >{cartCount}</div> : null;

  useEffect(() => {
    setMounted(true);
  }, []);

  const searchWithKeywords = (event) => {
    event.preventDefault();
    if (keywords.length === 0) {
      history.push('/s');
    } else {
      history.push(`/s/?keywords=${keywords}`);
    }
  }

  const getCategories = (id) => {
    const index = listingCategories.findIndex((category) => category.id === id);
    return listingCategories[index];
  }
  useEffect(() => {
    setTimeout(() => {
      if (recalculateCart) {
        recalculateCart();
      }
    }, 1 * 1000)

  }, [])

  return (
    <div className={css.topbar}>
      <div className={css.topcontainer}>
        <div className={css.tiptop}>
          <div className={css.searchbar}>
            <form onSubmit={searchWithKeywords} className={css.searchForm}>
              <input
                type="text"
                placeholder="Search your neighborhood"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
              <MagnifyingGlassIcon
                onClick={searchWithKeywords}
                disabled={keywords.length === 0}
                style={{ height: '2vw', width: '30px', color: '#d7d7d7', cursor: 'pointer' }}
                className={css.searchicon}
              />
            </form>
          </div>
          <div className={css.accountcart}>
            {
              authenticatedOnClientSide
                ? <div className={css.dropdown}>
                  <Link to="/account" className={css.dropdownHeading}>My Account</Link>
                  <div className={css.dropdownContent}>
                    <Link to="/messages">Messages</Link>
                    {
                      authenticatedOnClientSide
                        ? <>
                          {
                            !isUserCustomer
                              ? <Link to={`/listings`}>Listings</Link>
                              : null
                          }
                        </>
                        : null
                    }
                    <Link to="/orders">Orders</Link>
                    {!isUserCustomer
                      ? <Link to="/profile-settings">Profile settings</Link>
                      : null
                    }

                    <Link to="/account">Account settings</Link>
                    {authenticatedOnClientSide && <a onClick={() => { props.onLogout() }}>Log out</a>}
                  </div>
                </div>
                : <div className={css.dropdown}>
                  <Link to="/login" className={css.dropdownHeading}>Log In</Link>
                </div>
            }
            {
              authenticatedOnClientSide && isUserCustomer
                ? <>
                  <Link to="/my-likes">My Likes</Link>
                  <Link to="/cart" className={css.shoppingCart}>
                    <ShoppingCartIcon style={{ height: '25px', width: '25px', color: '#d7d7d7', marginRight: '3px' }} />
                    Cart
                    {authenticatedOnClientSide ? notificationDot : null}
                  </Link>
                </>
                : null
            }
          </div>
        </div>
        <div className={css.logocontainer}>
          <a href='/'>
            <img src={require('../icons/locagologo.webp')} alt={'Locago logo'}
              className={css.logo} />
          </a>
        </div>
        <nav>
          <ul>
            <li
              onMouseEnter={() => setHoveringOver('women')}
              onMouseLeave={() => setHoveringOver('')}
              className={css.womensApparel}>
              <a href="/s?pub_categoryLevel1=womens_apparel">Women's
                Apparel & Accessories
                {hoveringOver === "women" && <DropDownMenu column1={getCategories(category_ids.WOMEN_APPAREL)} column2={getCategories(category_ids.WOMEN_ACCESSORIES)} />}

              </a> </li>
            <li
              onMouseEnter={() => setHoveringOver('men')}
              onMouseLeave={() => setHoveringOver('')}
              className={css.mensApparel}><a href="/s?pub_categoryLevel1=mens_apparel">Men's
                Apparel & Accessories
                {hoveringOver === "men" && <DropDownMenu column1={getCategories(category_ids.MENS_APPAREL)} column2={getCategories(category_ids.MENS_ACCESSORIES)} />}
              </a>
            </li>
            <li
              onMouseEnter={() => setHoveringOver('home')}
              onMouseLeave={() => setHoveringOver('')}
              className={css.homeDecor}><a href="/s?pub_categoryLevel1=home">Home & Decor
                {hoveringOver === "home" && <DropDownMenu column1={getCategories(category_ids.HOME)} />}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div >
  );
};
// export default TopbarSectionComponent;

const mapStateToProps = state => {
  return state;
};


const mapDispatchToProps = dispatch => ({
  recalculateCart: () =>
    dispatch(recalculateCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopbarSectionComponent);
